import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image"
import { FaUserCheck } from "react-icons/fa"
import { FaChevronRight } from "react-icons/fa"
import { BsFillFileCheckFill } from "react-icons/bs"
import { Link } from 'gatsby'

import ContentContainer from '../../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../../support'
import HomeButton from '../../UI/Buttons/HomeButton';
import addQuery from '../../../utils/addQuery'

const HeaderWithImage = ({page}) => {

	return (
		<ContentContainer xsPadding={'30px 20px'}>
			<Main>
				<MainGetRate>
					<div dangerouslySetInnerHTML={{
						__html: page.headerWithImage?.headerWithColor.childMarkdownRemark.html,
					}}/>
					<Link to={`/personal-loan/${page.slug}#terms`}>
						<div className="subHeader" dangerouslySetInnerHTML={{
							__html: page.headerWithImage?.subheader.childMarkdownRemark.html,
						}}/>
					</Link>
					<div className="btnWrapper">
						<HomeButton
							height={BUTTON_SIZE.HEIGHT_L}
							padding={BUTTON_SIZE.PADDING_L}
							fontSize={BUTTON_SIZE.FONT_SIZE_L}
							background={BUTTON_COLOR.GREEN}
							backgroundHover={BUTTON_COLOR.GREEN_HOVER}
							color={BUTTON_COLOR.TEXT_COLOR_WHITE}
							text='Get My Rate'
							handleClick={() => addQuery(URL.QUOTE)}
							margin='0'
						/>
					</div>

					{/* here the href need to changed by slug */}
					<Question href={`/personal-loan/${page.slug}#personalLoansFaqs`}> 
						<div className="icon">
							<FaUserCheck />
						</div>
						<p>Am I eligible?</p>
						<div className="icon">
							<FaChevronRight />
						</div>
					</Question>
					<Question href={URL.RESUME}>
						<div className="icon">
							<BsFillFileCheckFill />
						</div>
						<p>Resume my application</p>
						<div className="icon">
							<FaChevronRight />
						</div>
					</Question>
				</MainGetRate>
				<MainImg>
					<GatsbyImage image={page.headerWithImage?.img?.gatsbyImageData} alt={page.headerWithImage?.img.title}/>
				</MainImg>
			</Main>
		</ContentContainer>
	)
}


const Main = styled.div`
	position: relative;
	z-index: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 16px;
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		justify-items: center;
	};
	@media screen and (max-width: 480px) {
		margin: 0;
	};


`
const MainGetRate = styled.div`
	max-width: 586px;
	a {
		&:hover {
			color: var(--base-navy);
		}
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};

	};
	.subHeader {
		p {
			font-size: 20px;
		}
		max-width: 450px;
		@media screen and (max-width: 768px) {
			text-align: center;
			margin-right: auto;
			margin-left: auto;
		};
	};

	.btnWrapper {
		padding: 40px 0;
		button {
			margin: 0;
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			button {
			margin: 0 auto;
			}

		}

	}
`
const Question = styled.a`
	display: flex;
	align-items: center;
	p {
		margin: 5px 0;
	};
	.icon {
		padding: 0 10px; 
		display: flex; 
		align-items: center;
	};
	&:hover {
		cursor: pointer;
	}
	@media screen and (max-width: 768px) {
		justify-content: center;
	}

`
const MainImg = styled.div`
	justify-self: center;
	img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 768px) {
		margin: 64px 0 30px 0;
		width: 60vw;
		height: 60vw;
	}
	@media screen and (max-width: 480px) {
		width: 80vw;
		height: 80vw;
	};

`

export default HeaderWithImage
