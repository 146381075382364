import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { MdLockOpen, MdLockOutline } from 'react-icons/md'

const LoanDetailsTable = () => {

	const dataObj = useStaticQuery(graphql`
		query ObjQuery {
			contentfulComponentJsonTable(tableName: {eq: "LoanDetailsTableObj"}) {
				data {
					td1 {
						content
					}
					td2 {
						content
					}
					td3 {
						content
					}
				}
			}
		}
	`)

	const items = dataObj.contentfulComponentJsonTable.data

	const getTd = (data) => {
		const tdArray = []
		Object.keys(data).forEach((key) => {
			tdArray.push(
				<td 
					key={key}
				> 
					{data[key].content}
				</td>
			)
		})
		return tdArray
	}

	return (
		<Table>
			<tbody>
				<tr>
					<Th>Loan details</Th>
					<Th>
						<div className='withIcon'>
							<MdLockOutline style={{ margin: '0 10px' }}/>
							Secured loans
						</div>
					</Th>
					<Th>
						<div className='withIcon'>
							<MdLockOpen style={{ margin: '0 10px' }}/>
							Unsecured loans
						</div>
					</Th>
				</tr>
				{/* first map to show the tr */}
				{items.map((item, index) => 
					<tr key={index}>
						{/* second map to show every td in each tr */}
						{getTd(item)}
					</tr>
				)}
			</tbody>
		</Table>
	)
}

const Table = styled.table`
	table-layout: fixed;
	font-family: QuicksandSemiBold;
	width: 800px;
	margin: 0 auto;
	border: 1px solid #d4dbe0;
	border-collapse: collapse;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	th, td {
		border: 1px solid #d4dbe0;
	}
	td {
		padding: 16px 20px;
	}
	td:nth-child(3n+1) {
		background-color: rgba(212,219,224,.3);
	}
	td:nth-child(3n-1), td:nth-child(3n) {
		font-family: QuicksandBold;
	}
	tr:nth-child(10n-2), tr:nth-child(10n-1), tr:nth-child(10n) {
		td:nth-child(3n-1), td:nth-child(3n) {
			color: var(--base-green);
			font-size: 19px;
			@media screen and (max-width: 479px) {
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 479px) {
		td {
			font-size: 12px;
			padding: 6px;
		}
	}
`

const Th = styled.th`
	background: var(--base-navy);
	color: rgb(255 255 255);
	padding: 13px 24px;
	text-align: left;
	font-size: 18px;
	border: 1px solid rgb(200 200 200);
	font-family: PoppinsBold;
	.withIcon {
		display: flex; 
		justify-content: flex-start; 
		align-items: center;
	}

	@media screen and (max-width: 479px) {
		font-size: 13px;
		padding: 6px;
		text-align: center;
		.withIcon {
			flex-direction: column;
			justify-content: start; 
			align-items: center;
		}
	}
`

export default LoanDetailsTable
