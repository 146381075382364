import React from 'react'
import styled from 'styled-components'

import addQuery from '../../../utils/addQuery'
import ContentContainer from '../../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../../support'
import HomeButton from '../../UI/Buttons/HomeButton';
import CMSImage from '../../UI/CMSImage'

const PersonalLoansMadeEasy = ({page}) => {

	const getItems = (data) => {
		const itemsArray = []
		data.personalLoansMadeEasyItems && data.personalLoansMadeEasyItems.forEach((item, index) => {
			itemsArray.push(
				<Item key={index}>
					<div style={{maxWidth:50}}>
						<CMSImage
							gatsbyImageData={item.icon?.gatsbyImageData}
							file={item.icon.file}
							alt={item.icon.title}
						/>
					</div>
					<h3>{item.header}</h3>
					<p>{item.content}</p>
				</Item>
			)
		})
		return itemsArray
	}

	return (
		<ContentContainer background='rgb(38 75 101)'>
			<PersonalLoansMadeEasyWrapper>
				<h2>{page.personalLoansMadeEasyHeader}</h2>
				<Items>
					{getItems(page)}
				</Items>
				<div className="btnWrapper">
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Get My Rate'
						handleClick={() => addQuery(URL.QUOTE)}
					/>
				</div>
			</PersonalLoansMadeEasyWrapper>
		</ContentContainer>
	)
}

const PersonalLoansMadeEasyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h2 {
		text-align: center; 
		color: rgb(255 255 255); 
		font-size: 40px;
		@media screen and (max-width: 991px) {
			font-size: 32px;
		};

	};
	.btnWrapper {
		padding-top: 56px; 
		margin: 0 auto
	}
`

const Items = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	color: rgb(255 255 255);
	margin-top: 30px;
	width: 100%;
	@media screen and (max-width: 991px) {
		justify-content: space-between;
		width: 100%;
	};
	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	};

`
const Item = styled.div`
	max-width: 410px;
	@media screen and (max-width: 991px) {
		max-width: 28vw;
	};
	@media screen and (max-width: 768px) {
		max-width: 60vw;
		margin-bottom: 30px;
	};


	div {
		${'' /* height: 48px; */}
		${'' /* width: auto; */}
		width: 48px;
		margin: 0 auto;
	};
	h3 {
		text-align: center;
		margin: 20px 0px;
	};
	p {
		text-align: center;
		width: 310px;
		margin: 0 auto;
		@media screen and (max-width: 991px) {
			width: 28vw;
		};	
		@media screen and (max-width: 768px) {
		width: 60vw;
		};
	}
`

export default PersonalLoansMadeEasy