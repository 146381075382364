import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'


import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';

const HereToHelp = () => {

  return (
    <Wrapper>
      <div className="content">
        <h3>Have some more questions?</h3>
        <p>Our support team is here to help.</p>
      </div>
      <div className="buttons">
        <div className="btnWrapper">							
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_S}
						padding={BUTTON_SIZE.PADDING_S}
						fontSize={BUTTON_SIZE.FONT_SIZE_S}
						background={BUTTON_COLOR.WHITE}
						backgroundHover={BUTTON_COLOR.WHITE_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_BLUE}
						text="View FAQs"
						handleClick={() => navigate(`/marketplace/help`)}
					/>
        </div>
        <div className="btnWrapper">
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_S}
						padding={BUTTON_SIZE.PADDING_S}
						fontSize={BUTTON_SIZE.FONT_SIZE_S}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Get In Contact'
						handleClick={() => navigate(`/contact`)}
					/>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
	background: linear-gradient(115deg, rgb(38 75 101) 50%, rgb(46 84 111) 0);
	margin: 60px 30px 0px 30px;
	padding: 24px;
	width: 960px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}

	.content {
		@media screen and (max-width: 991px) {
			width: 40%;
		};
		@media screen and (max-width: 768px) {
			width: 100%;
			margin: 0 auto;
		}


		h3 {
			margin: 0px 0px 16px 0px;
			color: rgb(255 255 255);
			@media screen and (max-width: 768px) {
				width: 100%;
				text-align: center;
			}

		};
		p {
			${'' /* text-align: center; */}
			color: rgb(255 255 255);
			margin: 0;
			@media screen and (max-width: 768px) {
				width: 100%;
				text-align: center;
			}
		};
	};
	.buttons {
		display: flex;
		justify-content: flex-end;
		align-Items: center;
		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
		}

		.btnWrapper {
			padding-left: 24px;
			@media screen and (max-width: 991px) {
				padding-left: 10px;
			};
			@media screen and (max-width: 768px) {
				margin: 10px;
			}

		}
	};
`

export default HereToHelp