import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../../../UI/ContentContainer'
import WhyGetAPersonalLoan from './WhyGetAPersonalLoan'
import LoanDetailsTable from './LoanDetailsTable'
import HereToHelp from '../../../blocks/HereToHelp'

const TheRightLoanForYou = ({whyGet}) => {
  const data = useStaticQuery(graphql`
    query TheRightLoanForYouQuery {
      contentfulGenericHeadingTextAndIcon (
        contentIdentifier: { eq: "TheRightLoanForYou" }
      ) {
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)
	return (
		<ContentContainer>
			<TheRightLoan>
				<div
          dangerouslySetInnerHTML={{
            __html: data.contentfulGenericHeadingTextAndIcon.content.childMarkdownRemark.html,
          }}
        />
				<LoanDetails>
					<LoanDetailsTable />
				</LoanDetails>
			</TheRightLoan>
			<WhyGet whyGet={whyGet}>
				<h2>Why get a personal loan?</h2>
				
				{/* here are the reasons for personal loan */}
				<WhyGetAPersonalLoan />

				<HereToHelp />

			</WhyGet>
		</ContentContainer>
	)
}

const TheRightLoan = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1020px;
	margin: 0 auto;
	@media screen and (max-width: 991px) {
		max-width: 100%;
	};

	h2 {
		text-align: center; 
		@media screen and (max-width: 991px) {
			font-size: 32px;
		};

	};
	p {
		text-align: center; 
		font-size: 20px;
		a {
			text-decoration: underline;
			&:hover {
				color: var(--base-navy);
			}
		}
	};
`
const WhyGet = styled.div`
	display: ${props => props.whyGet ? 'flex' : 'none'};
	flex-direction: column;
	align-items: center;
	margin-top: 110px;

	>h2 {
		text-align: center; 
		@media screen and (max-width: 991px) {
			font-size: 32px;
		};

	}
`
const LoanDetails = styled.div`
	margin-bottom: 10px;
`

export default TheRightLoanForYou