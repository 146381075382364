export const faqsMme = {

  "@type": "FAQPage",
  "mainEntity": [
      {
          "name": "Who can apply for a secured or unsecured personal loan?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "The first thing you should do is check your eligibility with the lender. If you’re looking to apply for a personal loan with SocietyOne, you’ll need to ensure you meet our lending criteria below: \n\nYou need to be at least 18 years of age\nBe an Australian citizen or a permanent resident of Australia\nYou must earn more than $30,000 per annum\nIt’s important that you have a good credit history.\nIf you’re applying for a secured personal loan, you will need to have an asset to pledge as security. At SocietyOne, your asset will need to meet a minimum value, depending on the amount you wish to borrow. Acceptable assets include: \n\nVehicles (passenger and light commercial vehicles) \nMotorbikes\nCaravans\nMarine (boats and personal watercrafts). \nWe do not accept heavy commercial vehicles. \n\nYou will need to provide asset details, proof of ownership or purchase invoice, and insurance as part of the verification process.",
              "@type": "Answer"
          }
      },
      {
          "name": "What will I need to get a personal loan?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "To get a personal loan, you may need to supply us with the following information and documents as part of your personal loan application:\n\nYour personal details – name, address, date of birth\nA driver’s licence or passport\nProof of your address - for example, a utility bill\nProof of your income in the form of payslips or bank statements\nDetails about your day-to-day expenses, and any other debts\n\n\nIf you’re applying for a secured personal loan, you’ll also need to provide asset details as part of the verification process. \n\nThe documents we require depend on your ownership of the vehicle but it will help to have these things handy:\n\nRegistration certificate \nProof of insurance\nInvoice or proof of private sale, if you are purchasing a vehicle",
              "@type": "Answer"
          }
      },
      {
          "name": "Are there any fees?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "We charge a one-off establishment fee that is included in your total loan amount. There are also no monthly fees or early repayment fees giving you the security of a fixed rate loan, with the flexibility to pay it off early. Visit our Rates & Fees page for further details.\n\nEstablishment fee‍\nNo up-front payments, just a one-off fee included in your total loan amount and paid over the life of the loan. Establishment fees start from: 0% with a maximum establishment fee of $595.\n\nLate Repayment Fee\nIf your loan repayment is unable to be processed and the payment remains outstanding, a late fee will be payable at 5, 14, 21 & 30 days after the payment date. Late Repayment fee: $35 per late payment\n\nDirect Debit Dishonour Fee\n‍It’s important to ensure there are enough funds in your account to cover each of your repayments to avoid dishonour fees. This fee is payable if we are unable to process a direct debit from your account. \nDishonour fee: $15 per dishonour\n\nEarly repayment fee\n‍Some lenders charge you for paying your loan out early. We don't! \nEarly repayment fee: $0",
              "@type": "Answer"
          }
      },
      {
          "name": "How much can I borrow, and for how long?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "The amount you can borrow depends on the loan options you choose and your serviceability (this is a calculation of how much you can afford to repay) \n\nWith an unsecured personal loan, you can borrow from $5,000 to $50,000. However, if you choose our secured option, you can borrow up to $70,000. \n\nWith either option, you should borrow the amount that best suits your situation, making sure you will be comfortable paying off each instalment.",
              "@type": "Answer"
          }
      },
      {
          "name": "What’s the difference between a secured personal loan & an unsecured personal loan?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Most personal loans come with two options, as a secured or unsecured loan. But which option is best for you? With a secured loan, you will need to provide an asset as security, such as a car. Secured loans can benefit from lower rates, longer loan terms and the ability to borrow larger amounts. They’re great if you own an asset outright, or are looking to buy a car. However, if you don't repay the loan on time, the lender has the right to seize and sell your asset. With an unsecured loan, You don't have to put up an asset for security. The benefit of an unsecured loan is faster, simpler application and assessment, so you could get the money faster.",
              "@type": "Answer"
          }
      },
      {
          "name": "What are the interest rates?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Fixed interest rate\nOur personal loans have a fixed interest rate, meaning your interest rate doesn't change during the life of your loan and your regular repayments are always the same. \n\nFixed rate personal loans can help make it easier for you to budget. With no early repayment fees, our fixed rate loans give you more freedom and allow you to pay off your debt sooner.\n\nOur fixed rates for unsecured personal loans start from 5.95% p.a* \n\nOur fixed rates for secured personal loans start from 4.95% p.a* ‍\n\n\nComparison rate\nIf you’re looking to compare personal loans, the comparison rate is the most transparent way to compare the true costs. A comparison rate takes into account the interest rate as well as all of the fees and charges that are payable (such as establishment fees), making it easier to understand the complete cost of the personal loan with a more transparent comparison of the products.\n\nIf you’re looking to compare personal loans, the comparison rate is the most transparent way to compare the true costs. A comparison rate takes into account the interest rate as well as all the fees and charges that are payable (such as establishment fees), making it easier to understand the total cost of the personal loan with a more transparent comparison of the products.\n\nOur comparison rates for unsecured personal loans start from 5.95% p.a*\n\nOur comparison rates for secured personal loans start from 4.95% p.a*",
              "@type": "Answer"
          }
      },
      {
          "name": "Are there any certain amounts or terms I should be aware of?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Loan amount limits‍\nThe minimum and maximum amounts you are able to borrow:\n\n$5,000 to $50,000 for unsecured personal loans\n$5,000 to $70,000 for secured personal loans\n\nLoan terms‍\nYour repayments are split over your loan term. A shorter loan term means less interest is paid over the life of the loan, while loans with longer terms will have lower regular repayments.\n\nYou can choose the loan term that suits you:\n\n2 years, 3 years or 5 years for unsecured personal loans\n2 years, 3 years, 5 years or 7 years for secured personal loans.",
              "@type": "Answer"
          }
      },
      {
          "name": "How is the interest rate of my loan calculated?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Our interest rates are personalised for each customer and are determined based upon factors such as your credit score - the higher your score, the lower your interest rate.",
              "@type": "Answer"
          }
      },
      {
          "name": "Are there any additional fees or charges on top of my loan repayments?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "We charge a one-off establishment fee that is included in your total loan amount. There are no monthly fees or early repayment fees.",
              "@type": "Answer"
          }
      },
      {
          "name": "Is a credit check mandatory in order to apply for a personal loan?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Yes, we use this and assess your financial situation to calculate your interest rate & reward borrowers who have maintained good credit scores. Receiving a rate quote from us will not impact your credit score. We use what is called a 'soft call' to get your credit score from the credit bureau which allows us to calculate a personalised rate for you. However, once you have submitted your loan application, we will conduct a credit check as part of our assessment process. This check is known as a 'hard call' and will be reflected in your credit score.",
              "@type": "Answer"
          }
      },
      {
          "name": "What loan terms can I choose from and can I pay my loan off early?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "We offer 2, 3 or 5 year terms with our unsecured loans, or up to 7 years with our secured loan option. \n\nYou also have the freedom to pay out your loan early in full or make additional lump sum payments during the life of your loan with no extra fees or penalties.",
              "@type": "Answer"
          }
      },
      {
          "name": "What repayment options are available?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Personal loan repayments can be made in either fortnightly or monthly instalments by direct debit. \n\nYour regular repayments are calculated based on your loan amount, interest rate, loan term (2, 3 or 5 years with an unsecured loan or up to 7 years with a secured loan), and whether you have chosen fortnightly or monthly repayments.",
              "@type": "Answer"
          }
      },
      {
          "name": "How long will it take for me to receive my funds?",
          "@type": "Question",
          "acceptedAnswer": {
              "text": "Once you're approved the funds will be transferred to your bank account, usually within 24 hours.",
              "@type": "Answer"
          }
      }
  ]
}