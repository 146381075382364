import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../../../support'
import HomeButton from '../../../UI/Buttons/HomeButton'
import addQuery from '../../../../utils/addQuery'

const WhyGetAPersonalLoan = () => {

	const data = useStaticQuery(graphql`
		query ComponentWhyGetAPersonalLoanQuery {
			allContentfulComponentWhyGetAPersonalLoan(sort: { fields: [createdAt], order: ASC }) {
				nodes {
					img {
						gatsbyImageData(placeholder:NONE)
						title
					}
					icon {
						gatsbyImageData(placeholder:NONE)
						title
					}
					content {
						childMarkdownRemark {
							html
						}
					}
					title
					createdAt(formatString: "MMM Do, YYYY, h:mm:ss a")
				}
			}
		}
	`)

	const reasons = data.allContentfulComponentWhyGetAPersonalLoan.nodes

	return (
		<>
			{reasons.map((item, index) =>
				<Accordion disableGutters style={{ boxShadow: 'none', borderBottom: '1px solid rgb(245 245 245)' }} key={index}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						style={{ padding: '0' }}
					>
						<Reason key={item.index}>
							<div className='imgWrapper'>
								{
									item.icon?.gatsbyImageData &&
									<GatsbyImage image={item.icon?.gatsbyImageData} alt={item.icon.title} />
								}
							</div>
							<h3>
								{item.title}
							</h3>
						</Reason>
					</AccordionSummary>
					<AccordionDetails>
						<Content>
							<div className='main'>
								<div className='text' dangerouslySetInnerHTML={{ __html: item.content.childMarkdownRemark.html }} />
								<div className='imgWrapper'>
									<GatsbyImage image={item.img?.gatsbyImageData} alt={item.img.title} style={{ borderRadius: '16px' }} />
								</div>
							</div>
							<div className='btnWrapper'>
								<HomeButton
									height={BUTTON_SIZE.HEIGHT_L}
									padding={BUTTON_SIZE.PADDING_L}
									fontSize={BUTTON_SIZE.FONT_SIZE_L}
									background={BUTTON_COLOR.GREEN}
									backgroundHover={BUTTON_COLOR.GREEN_HOVER}
									color={BUTTON_COLOR.TEXT_COLOR_WHITE}
									text='Get My Rate'
									handleClick={() => addQuery(URL.QUOTE)}
								/>
							</div>
						</Content>
					</AccordionDetails>
				</Accordion>
			)}
		</>
	)
}

const Reason = styled.div`
		display: flex; 
		justify-content: flex-start; 
		align-items: center;
	.imgWrapper {
		width: 50px; 
		height: auto; 
		padding: 0; 
		margin: 0 24px 0 0;
	};
	h3 {
		padding: 0; 
		margin: 20px 0; 
		color: rgb(38 75 101);
	}
`

const Content = styled.div`
	width:1020px; 
	display: flex; 
	flex-direction: column; 
	justify-content: flex-start; 
	padding: 0;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	.main {
		display: flex; 
		justify-content: space-between; 
		align-items: flex-start;
		.text {
			color: rgb(38 75 101);
			font-size: 16px; 
			width: 564px; 
			@media screen and (max-width: 991px) {
				width: 50%;
				p {
					margin: 0 0 15px 0 !important;
				};
			};

		};
		.imgWrapper {
			width: 423px;
			@media screen and (max-width: 991px) {
				width: 47%;
			};

		};
	};
	.btnWrapper {
		padding-top: 20px;
	}
`

export default WhyGetAPersonalLoan
