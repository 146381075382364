import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import loadable from '@loadable/component'

import Seo from '../components/seo'
import Layout from '../layouts/layout'
import BeInTheKnow from '../components/UI/BeInTheKnow'
import DontJustTake from '../components/UI/DontJustTake'
import ResourceCenter from '../components/UI/ResourceCenter'
import EasilyCalculate from '../components/ForPersonalLoans/PersonalLoanPages/EasilyCalculate'
import AwardWinningPLs from '../components/UI/AwardWinningPLs'
import TheRightLoanForYou from '../components/ForPersonalLoans/PersonalLoanPages/TheRightLoanForYou'
import PersonalLoansMadeEasy from '../components/ForPersonalLoans/PersonalLoanPages/PersonalLoansMadeEasy'
import HowItWorksAndCompare from '../components/UI/HowItWorksAndCompare'
import HeaderWithImage from '../components/ForPersonalLoans/PersonalLoanPages/HeaderWithImage'
import ContentContainer from '../components/UI/ContentContainer'
import BigButtons from '../components/ForPersonalLoans/PersonalLoanPages/BigButtons'
import Benefit from '../components/UI/Benefit'
import { parseJson } from '../support'
import BgCircleNavy from '../assets/images/svg/bg-circle-navy.svg'
import BgCircleGreenBlue from '../assets/images/svg/bg-circle-green-blue.svg'
import { faqsMme } from '../support/structuredDataConfig/faqsMme'

const PersonalLoansFaqs  = loadable(() => import('../components/ForPersonalLoans/PersonalLoanPages/PersonalLoansFaqs'))
const TopicDescAndImg  = loadable(() => import('../components/UI/TopicDescAndImg'))

const PersonalLoanPagesTemplate = ({ data, path }) => {

  const page = data.contentfulPagePersonalLoan

  const escapedStructuredData = page.seoStructuredData?.internal?.content

  const structuredData = parseJson(escapedStructuredData)
  const newStructuredData = {...structuredData, "faq": faqsMme}
  
  return (
    <Layout hasGetYourRate={true}>
      <Seo title={page.seoMetaTitle} description={page.seoMetaDescription} image={page.seoMetaImage?.fluid?.src} path={path} structuredData={newStructuredData} />
      <HeroWrapper>
        <HeaderWithImage page={page}/>
        <Benefit page={page}/>
        <ImageWrapper>
					<BgCircleNavy className="small" />
					<BgCircleGreenBlue className="big" />
				</ImageWrapper>
      </HeroWrapper>
      <EasilyCalculate header={page.calculator?.header} content={page.calculator?.subheader}/>
      <ContentContainer>
        {page.personalLoansTopicDescAndImgCategory && <TopicDescAndImg categoryQuery={page.personalLoansTopicDescAndImgCategory} />}
      </ContentContainer>
      <HowItWorksAndCompare subHeader={page.howItWorks?.subheader} hasHowItWorks={true}/>
      <PersonalLoansMadeEasy page={page} />
      <BigButtons filter={page.slug} />
      <TheRightLoanForYou />
      <Hr/>
      <AwardWinningPLs />
      <ResourceCenter type={page.filter}/>
      <div id='personalLoansFaqs'></div>
      {page.faqsSubheader && <PersonalLoansFaqs subHeader={page.faqsSubheader} page={page}/>}
      <BeInTheKnow />
      <DontJustTake />
    </Layout>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
`
const ImageWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;

	.small {
		position: absolute;
		left: auto;
		top: auto;
		right: 5vw;
		bottom: 30%;
    width: 6vw;
    height: auto;
    max-width: 90px;
		max-height: 600px;
	}
	.big {
    position: absolute;
    left: auto;
    top: -15vw;
    right: -15.9vw;
    bottom: auto;
    width: 49vw;
    height: 49vw;
    max-height: 700px;
    max-width: 700px;
	}
  @media only screen and (max-width: 991px) {
    .small {
      bottom: 47%;
    }
  }
  @media screen and (max-width: 767px) {
    .small {
      display: none;
    }
    .big {
      left: auto;
      top: auto;
      right: 1vw;
      bottom: 29.9em;
      width: 50vw;
      height: 50vw;
    }
  }
  @media screen and (max-width: 479px) {
    .big {
      bottom: 43%;
      width: 60vw;
      height: 60vw;
    }
  }
`

const Hr = styled.hr`
  background-color: rgba(225 225 225);
  height: 1px;
  border: none;
  width: 98vw;
`



export default PersonalLoanPagesTemplate

export const pageQuery = graphql`
  query PersonalLoanPagesBySlug(
    $slug: String!
  ) {
    contentfulPagePersonalLoan(slug: {eq: $slug}) {
      slug
      headerWithImage {
        headerWithColor {
          childMarkdownRemark {
            html
          }
        }
        subheader {
          childMarkdownRemark {
            html
          }
        }
        img {
          gatsbyImageData(width:500, height:500, placeholder: NONE)
          title
        }
      }
      benefits {
        header {
          childMarkdownRemark {
            html
          }
        }
        icon {
          gatsbyImageData(placeholder:NONE)
          title
          file {
            contentType
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      calculator {
        header
        subheader
      }
      personalLoansTopicDescAndImgCategory
      howItWorks {
        subheader
      }
      personalLoansMadeEasyHeader
      personalLoansMadeEasyItems {
        icon {
          title
          gatsbyImageData(placeholder:NONE)
          file {
            contentType
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        header
        content
      }
      filter
      faqsSubheader
      faqs {
        ... on ContentfulComponentFaQs {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }        
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
      seoStructuredData {
        internal {
          content
        }
      }
    }
  }
`

