import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../../UI/ContentContainer'
import CMSImage from '../../UI/CMSImage'

const BigButtons = ({filter}) => {

  const data = useStaticQuery(graphql`
    query BigButtonsQuery {
      allContentfulComponentPersonalLoanPagesBigButtons {
        nodes {
          header
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          filter
        }
      }
    }
  `)
  
  const items = data.allContentfulComponentPersonalLoanPagesBigButtons.nodes.filter(item => item.filter !== filter)

  return (
    <ContentContainer background="var(--base-gray)">
      <Header>A personal loan that's right for you</Header>
      <LoansList>
        {items.map((item, index) => 
          <Item to={`/personal-loan/${item.filter}`} key={index}>
            <div className="icon">
              <CMSImage
                svg={item.icon.svg}
                gatsbyImageData={item.icon?.gatsbyImageData}
                file={item.icon.file}
                alt={item.icon.title}
              />
            </div>
            <h3>{item.header}</h3>
          </Item>
        )}
      </LoansList>
    </ContentContainer>
  )
}

const LoansList = styled.div`
  max-width: 900px;
  margin:0 auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);

	@media screen and (max-width: 991px) {
    grid-gap: 20px;
    max-width: 688px;
	};
	@media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 352px;
	};

`
const Header = styled.h2`
  margin: 0 auto 60px;
  text-align: center;
	@media screen and (max-width: 991px) {

  };
  @media screen and (max-width: 768px) {

  };

`
const Item = styled(Link)`
  background-color: var(--white);
  width: 200px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid rgb(225 225 225);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .25s  ease-in;
  h3 {
    margin-top: 16px;
    text-align: center;
    font-family: QuicksandSemiBold;
    font-size: 18px;
  }
  &:hover {
    transform: translateY(-8px);
    transition: all .15s  ease-in;

  }
  @media screen and (max-width: 991px) {
    width: 160px;
    height: 160px;

  };
  @media screen and (max-width: 768px) {

  };

`

export default BigButtons